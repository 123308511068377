import { faCommentAltLines, faHashtag, faStream, faTypewriter, faWallet, faStopwatch } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Popconfirm, Form, Row, Col, Input, Image } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./TestMessage.module.css";
import useSpinner from '../../../Hooks/useSpinner';
import TYPES from "../../../reducers/types";
import CONSTANTS from "../../../utils/const";
import CampaignVozApi from "../../../Api/CampaignVozApi";
import useAuth from "../../../Hooks/useAuth";
import encodingSevenBits, { currencyFormat, notificationError, notificationOk, roundNumber } from "../../../utils/helpers";
import { getGradientColor, purpleColor } from "../../../utils/colorsCss";
import HelperButton from "../../HelperButton/HelperButton";
import ReactCountryFlag from "react-country-flag"
import { useEffect } from "react";
import imageSound from "../../../assets/resources/sound.PNG";
import AudioPlayer from "../../AudioPlayer/AudioPlayer";
import ButtonCustom from "../../Button/Button";
import TextToSpeechApi from "../../../Api/TextToSpeechApi";

const TestMessage = ({message,
  accountId,
  senderId,
  customFields,
  fileHeaders,
  fileData,
  voice,
  defualtShortLink}) => {

  useEffect(() => {
    if(message) {
      getMessage();
      setPreviewDsabeled(false);
    }
  }, [message])

  useEffect(() => {
    if(voice) {
      setPreviewDsabeled(false);
    }
  }, [voice])

  const [t] = useTranslation("campaign-voz");
  const { auth } =  useAuth();
  const [form] = Form.useForm();
  const { spinnerDispacth } = useSpinner();

  const [messagePartsTest, setMessagePartsTest] = useState([]);
  const [isValidateMessage, setIsValidateMessage] = useState(true);
  const [destination, setDestination] = useState(null);
  const [messageTest, setMessageTest] = useState(null);
  const [costMessageTest, setCostMessageTest] = useState(0);
  const [isBasicEncoding, setIsBasicEncoding] = useState(true);
  const [, setMessageCount] = useState(0);
  const [charUsed, setCharUsed] = useState(0);
  const [audio, setAudio] = useState(null);
  const [audioDuration, setAudioDuration] = useState(0);
  const [previewDisabeled, setPreviewDsabeled] = useState(false);

  const { sendMessage, getMessageCost } = CampaignVozApi();
  const { getAudio } = TextToSpeechApi();

  const testMessage = () => {
    form.validateFields()
      .then(values => {
        spinnerDispacth({
          type: TYPES.SPINNER.SHOW,
          payload: { show: true, item: CONSTANTS.SPINNERITEM.VALIDATEMESSAGECAMPAIGN }
        });

        const { mobileNumber } = values;

        sendMessage(auth.companyId, accountId, auth.userName, {
          body: messageTest,
          to: mobileNumber,
          from: senderId.length > 0 ? senderId : "1234",
          language: voice.lan,
          shortName: voice.shortName,
          gender: voice.gender,
        }, auth.accessToken).then(response => {
          form.resetFields();

          spinnerDispacth({
            type: TYPES.SPINNER.SHOW,
            payload: { show: false, item: CONSTANTS.SPINNERITEM.VALIDATEMESSAGECAMPAIGN }
          });

          notificationOk(t("content-message.notification-ok-message"));
          setIsValidateMessage(true);
          setDestination(null);
          setCostMessageTest(0);
        }).catch(error => {
          console.error(error);
          
          spinnerDispacth({
            type: TYPES.SPINNER.SHOW,
            payload: { show: false, item: CONSTANTS.SPINNERITEM.VALIDATEMESSAGECAMPAIGN }
          });

          notificationError(t("content-message.notification-error-message"));
        });

      });
  }

  const validateMessage = () => {
    form.validateFields()
      .then(values => {

        spinnerDispacth({
          type: TYPES.SPINNER.SHOW,
          payload: { show: true, item: CONSTANTS.SPINNERITEM.VALIDATEMESSAGECAMPAIGN }
        });

        const { mobileNumber } = values;
        const firstRow = fileData[0].value;

        let msg = message;
        customFields.forEach(field => {
          if(field !== CONSTANTS.SHORTLINK) {
            let column = fileHeaders.find(x => x.name === field);
            let row = firstRow.find(x => x.column === column.column);
            msg = msg.replaceAll(`${CONSTANTS.WILDCARD}${field}${CONSTANTS.WILDCARD}`, row.value);
          }
        });

        getMessageCost(auth.companyId, mobileNumber, msg)
          .then(response => {
                        
            spinnerDispacth({
              type: TYPES.SPINNER.SHOW,
              payload: { show: false, item: CONSTANTS.SPINNERITEM.VALIDATEMESSAGECAMPAIGN }
            });
            
            setIsValidateMessage(false);
            setDestination({
              countryName: response.countryName,
              countryCode: response.countryCode,
              prefix: response.prefix
            });

            setCostMessageTest(response.unitRate);
          }).catch(error => {

            spinnerDispacth({
              type: TYPES.SPINNER.SHOW,
              payload: { show: false, item: CONSTANTS.SPINNERITEM.VALIDATEMESSAGECAMPAIGN }
            });
            
            notificationError(t(error));
          })
      });
  }

  const getMessage = async () => {
    if(message.length === 1 && message == " ") {
      setMessagePartsTest([]);
      setMessageCount(0);
      setMessageTest("");
      setCharUsed(0);
    } else {
      const firstRow = fileData[0].value;

      let msg = message.replaceAll(`${CONSTANTS.WILDCARD}${CONSTANTS.SHORTLINK}${CONSTANTS.WILDCARD}`, defualtShortLink);
      customFields.forEach(field => {
          let column = fileHeaders.find(x => x.name === field);
          let row = firstRow.find(x => x.column === column.column);
          msg = msg.replaceAll(`${CONSTANTS.WILDCARD}${field}${CONSTANTS.WILDCARD}`, row.value);
      });

      let hasSpecialCharacters = false;
      for (let index = 0; index < msg.length; index++) {
        hasSpecialCharacters = !encodingSevenBits.includes(msg[index]);
        if (hasSpecialCharacters) {
          break;
        }
      }

      let smsParts = hasSpecialCharacters ? Math.ceil(msg.length / 70) : Math.ceil(msg.length / 160);

      let startPosition = 0;
      let endPosition = hasSpecialCharacters ? 70 : 160;
      let arrayMessages = [];

      for (let index = 0; index < smsParts; index++) {
        let newMessage = msg.substring(startPosition, endPosition);
        arrayMessages.push(newMessage);
        startPosition = endPosition;
        endPosition = hasSpecialCharacters ? endPosition + 70 : endPosition + 160;
      }

      setMessagePartsTest(arrayMessages);
      setMessageCount(arrayMessages.length);
      
      let ms = arrayMessages.join("");
      setMessageTest(ms);
      setCharUsed(ms.length);
      
      var isBasic = isBasicEncoding;

      let chart = ms.charAt(ms.length - 1);
      isBasic = encodingSevenBits.includes(chart) ? true : false;

      if (isBasic && !hasSpecialCharacters) {
        setIsBasicEncoding(true);
      } else {
        setIsBasicEncoding(false);
      }
    }
  }

  const onClickPreview = () => {

    spinnerDispacth({
      type: TYPES.SPINNER.SHOW,
      payload: { show: true, item: CONSTANTS.SPINNERITEM.GETAUDIOMESSAGE }
    });

    let raw = {
      Language: voice.lan,
      Gender: voice.gender,
      ShortName: voice.shortName,
      Message: messageTest
    }

    getAudio(raw)
    .then(result => {
      const blob = new Blob([result], { type: "audio/wav" });
      const ctx = new AudioContext();
      
      ctx.decodeAudioData(result)
      .then(decode => {
        setAudioDuration(roundNumber(decode.duration));
      });

      const url = window.URL.createObjectURL(blob);
      setAudio(url);

      spinnerDispacth({
        type: TYPES.SPINNER.SHOW,
        payload: { show: false, item: CONSTANTS.SPINNERITEM.GETAUDIOMESSAGE }
      });
    })
    .catch(error => {
      spinnerDispacth({
        type: TYPES.SPINNER.SHOW,
        payload: { show: false, item: CONSTANTS.SPINNERITEM.GETAUDIOMESSAGE }
      });
      console.error(error);
    });
  }

  return (
    <div className={styles['test-message-content']}>
      <div className={styles['test-message-info']}>
        <div className={styles['message-information']}>
          <div className={styles['message-information-text']}>
            <p>{t("content-message.label-chars")}
              <HelperButton 
                  message={""}
                  color={getGradientColor(purpleColor)}
              />
            </p>
            <p className={styles['message-information-text-value']}>
              <FontAwesomeIcon style={{ marginRight: "5px" }} icon={faHashtag} /> {charUsed}
            </p>
          </div>
        </div>
        <div className={styles['message-information']}>
          <div className={styles['message-information-text']}>
            <p>{t("content-message.label-audio-time")}
              <HelperButton 
                  message={""}
                  color={getGradientColor(purpleColor)}
              />
            </p>
            <p className={styles['message-information-text-value']}>
              <FontAwesomeIcon style={{ marginRight: "5px" }} icon={faStopwatch} /> {audioDuration}" S
            </p>
          </div>
        </div>
        {
          destination && (
            <div className={styles['message-information']}>
              <div className={styles['message-information-text']}>
                <p>{t("content-message.label-test-destination")}</p>
                <p className={styles['message-information-text-cuntry-value']}>
                  <ReactCountryFlag
                    countryCode={destination?.countryCode}
                    svg
                    style={{
                      width: '1.5em',
                      height: '1.5em',
                      marginRight: 5
                    }}
                    title={destination.countryCode}
                  />
                  +{destination?.prefix} {destination?.countryName}
                </p>
              </div>
            </div>
          )
        }
        {
          costMessageTest !== 0 && (
            <div className={styles['message-information']}>
              <div className={styles['message-information-text']}>
                <p>{t("content-message.label-test-message-cost")}</p>
                <p className={styles['message-information-text-value']}>
                  <FontAwesomeIcon style={{ marginRight: "5px" }} icon={faWallet} /> {currencyFormat(costMessageTest, 4)}
                </p>
              </div>
            </div>
          )
        }
      </div>
      <div className={styles['test-message-preview']}>
        <div className={styles['test-message-audio']}>
          <Image 
            src={imageSound}
            style={{ marginBottom: 10 }}  
          />
          <ButtonCustom 
            disabled={voice && messagePartsTest.length > 0 && previewDisabeled === false ? false : true} 
            style={{ width: 300, marginBottom: 10, marginRight: 0 }}
            type="primary" 
            onClick={onClickPreview}
          >
            {t("content-message.button-audio-preview")}
          </ButtonCustom>
          <AudioPlayer src={audio} />
        </div>
        <Form
          layout="vertical"
          form={form}
          initialValues={{
            initialState: false,
          }}
        >          
          <Row gutter={16}>
            <Col span={24}>
              <div className={styles['test-message-phone']}>
                <Form.Item
                  name="mobileNumber"
                  label={t("content-message.label-test-mobile-number")}
                  rules={[{ required: true, message: 'Please enter the mobile number' }]}
                >
                  <Input
                    style={{ width: 300, margin: 0 }}
                    placeholder={t("content-message.label-test-mobile-number-placeholder")}
                  />
                </Form.Item>
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <div className={styles['test-message-send-button']}>
              {
                isValidateMessage ? (
                  <Button
                    type="secondary"
                    onClick={validateMessage}
                    style={{ width: 300, margin: 0 }}
                    disabled={voice && messagePartsTest.length > 0 && previewDisabeled === false ? false : true} 
                  >
                    {t("content-message.button-test-validate")}
                  </Button>
                ) : (
                  <Popconfirm title={t("content-message.confirm-test")}
                    onConfirm={testMessage}
                    okText={t("content-message.confirm-test-button")}
                    cancelText={t("content-message.cancel-test-button")}
                  >
                    <Button
                      type="secondary"
                      style={{ width: 300, margin: 0 }}
                    >
                      {t("content-message.button-test-send")}
                    </Button>
                  </Popconfirm>
                )
              }
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
}
 
export default TestMessage;