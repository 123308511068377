import React, { useEffect, useState, useContext } from 'react';
import { Select, Drawer, Input, Form, Button, Popconfirm } from 'antd';
import { useTranslation } from 'react-i18next';
import { orangeColor, getGradientColor, purpleColor } from "../../../utils/colorsCss";
import HelperButton from "../../HelperButton/HelperButton";
import { AuthContext } from "../../../contexts/authRedirect";

const AccountForm = ({visible, onClose, onCreate, users}) => {
    
    const [t] = useTranslation("account");
    const { TextArea } = Input;
    const [form] = Form.useForm();
    const [userOptions, setUsersOptions] = useState([]);
    const { auth } = useContext(AuthContext);
    
    useEffect(() => {
        if (users) {
            const filterUsers = users.filter(user => user.id !== auth.userId);
            const options = filterUsers.map((user) => {
                return {
                    label: user.displayName,
                    value: user.id
                };
            });
            setUsersOptions(options);
        }
    }, [users]);

    return (
        <Drawer
            visible={visible}
            title={t("create-account.title")}
            headerStyle={{background: getGradientColor(orangeColor)}}
            width={720}
            onClose={() => {
                form.resetFields();
                onClose();
            }}
            footer={
                < div
                    style={{
                        textAlign: 'center',  
                    }}
                >
                    <Button onClick={() => {
                        form.resetFields();
                        onClose();
                    }}
                        style={{ marginRight: 50 }}>
                        {t("create-account.button-cancel")}
                    </Button>
                    <Popconfirm title={t("create-account.button-submit-confirm")}
                      okButtonProps={
                            {
                                "data-testid": "okButtonConfirm" //Important to do test
                            }
                        }
                        onConfirm={() => {
                            form
                                .validateFields()
                                .then(values => {
                                    onCreate(values);
                                    form.resetFields();
                                })
                                .catch(info => {
                                    console.log('Validate Failed:', info);
                                });
                        }}
                        okText="yes"
                        cancelText="no"
                    >
                        <Button
                            type="primary"
                            dataName="btnSave">
                            {t("create-account.button-submit")}
                        </Button>
                    </Popconfirm>
                </div >
            }
        >
            <Form
                data-testid="drawer-content"
                form={form}
                layout="vertical"
                name="form_in_modal"
            >
                <Form.Item
                    name="name"
                    label={t("create-account.label-name")}
                    rules={[
                        {
                            required: true,
                            message: 'Please input the name!',
                        }
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="description"
                    label={t("create-account.label-description")}
                    rules={[
                        {
                            required: true,
                            message: 'Please input a description!',
                        }
                    ]}
                >
                    <TextArea rows={4} type='phone' />
                </Form.Item>
                <Form.Item
                    label={
                        <span>
                            {t("create-account.label-users")}
                            <HelperButton
                                message={t("content-message.tooltip-accounts-users")}
                                color={getGradientColor(purpleColor)}
                            />
                        </span>
                    }
                    name="users"
                    >
                    <Select
                        mode="multiple"
                        style={{ width: '100%' }}
                        placeholder={t("create-account.placeholder-users")}
                        options={userOptions}
                    />
                </Form.Item>
            </Form>
        </Drawer>
    );
}

export default AccountForm;