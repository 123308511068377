import { faTag, faCalendarAlt, faCalendarWeek, faCommentAlt, faWallet } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import BalanceApi from "../../../Api/BalanceApi";
import useAuth from "../../../Hooks/useAuth";
import useSpinner from "../../../Hooks/useSpinner";
import TYPES from "../../../reducers/types";
import CONSTANTS from "../../../utils/const";
import styles from "./CampaignSummary.module.css";
import { currencyFormat, dateFormat } from '../../../utils/helpers';
import { useTranslation } from "react-i18next";

const CampaignSummary = ({accountId, campaignName, totalMessage, summary, schedule, onBalance}) => {

  const [t] = useTranslation("campaign-bulk");

  const { GetAccountBalanceByAccountId } = BalanceApi();
  const { spinnerDispacth } = useSpinner();
  const { auth } =  useAuth();
  const [accountBalance, setAccountBalance] = useState({
    id: null,
    accountName: "",
    lastAddedBalance: null,
    isDefaultAccount: false,
    totalBalance: 0,
    availableBalance: 0,
    onHoldBalance: 0
  });

  const MAX_CHARACTERS = 30;

  const truncateText = (text, maxLength) => {
    return text.length > maxLength ? text.slice(0, maxLength) + "..." : text;
  };

  useEffect(() => {

    spinnerDispacth({
      type: TYPES.SPINNER.SHOW,
      payload: { show: true, item: CONSTANTS.SPINNERITEM.ACCOUNTBALANCEBYACCOUNT }
    });

    spinnerDispacth({
      type: TYPES.SPINNER.SHOW,
      payload: { show: false, item: CONSTANTS.SPINNERITEM.SUMMARY }
    });

    GetAccountBalanceByAccountId(auth.companyId, accountId, auth.accessToken)
     .then(response => {
      onBalance(response.availableBalance < summary.estimatedCampaignCost);
      setAccountBalance(response);
      spinnerDispacth({
        type: TYPES.SPINNER.SHOW,
        payload: { show: false, item: CONSTANTS.SPINNERITEM.ACCOUNTBALANCEBYACCOUNT }
      });
     })
     .catch(error => {
      console.error(error);
      spinnerDispacth({
        type: TYPES.SPINNER.SHOW,
        payload: { show: false, item: CONSTANTS.SPINNERITEM.ACCOUNTBALANCEBYACCOUNT }
      });
     })
  }, [])


  return (
    <div className={styles['summary-content']}>
      <div className={styles['summary-info']}>
        <div className={styles['message-information']}>
          <div className={styles['message-information-text']}>
            <p>{t("summary.campaign-name")}</p>
            <p className={`${styles['message-information-text-value']} ${styles['name-campaign']}`}>
              <FontAwesomeIcon style={{ marginRight: "5px" }} icon={faTag} />
              {truncateText(campaignName, MAX_CHARACTERS)}
            </p>
          </div>
        </div>
        <div className={styles['message-information']}>
          <div className={`${styles['message-information-text']} ${styles['orange-color']}`}>
            <p>{t("summary.available-new-balance")}</p>
            <p className={styles['message-information-text-value']}>
              <FontAwesomeIcon style={{ marginRight: "5px" }} icon={faWallet} /> {currencyFormat(accountBalance.availableBalance, 2)}
            </p>
          </div>
        </div>
        <div className={styles['message-information']}>
          <div className={styles['message-information-text']}>
            <p>{t("summary.total-message")}</p>
            <p className={styles['message-information-text-value']}>
              <FontAwesomeIcon style={{ marginRight: "5px" }} icon={faCommentAlt} /> {totalMessage}
            </p>
          </div>
        </div>
      </div>
      <div className={styles['summary-info']}>
        <div className={styles['message-information']}>
          <div className={styles['message-information-text']}>
            <p>{t("summary.start-date")}</p>
            <p className={styles['message-information-text-value']}>
              <FontAwesomeIcon style={{ marginRight: "5px" }} icon={faCalendarAlt} /> {dateFormat(schedule[0].DateScheduled, true)}
            </p>
          </div>
        </div>
        <div className={styles['message-information']}>
          <div className={`${accountBalance.availableBalance < summary.estimatedCampaignCost ? styles['red-color'] : styles['orange-color']} ${styles['message-information-text']}`}>
            <p>{t("summary.total-cost")}</p>
            <p className={styles['message-information-text-value']}>
              <FontAwesomeIcon style={{ marginRight: "5px" }} icon={faWallet} /> {currencyFormat(summary.estimatedCampaignCost, 5)}
            </p>
          </div>
        </div>
        <div className={styles['message-information']}>
          <div className={styles['message-information-text']}>
            <p>{t("summary.schedule")}</p>
            {
              schedule.map((item, index) => (
                <p key={index} className={styles['message-information-text-value']}>
                  <FontAwesomeIcon style={{ marginRight: "5px" }} icon={faCalendarWeek} /> {dateFormat(item.DateScheduled, true)} - {item.percentage}%
                </p>
              ))
            }
          </div>
        </div>
      </div>      
    </div>
  );
}
 
export default CampaignSummary;