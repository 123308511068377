import useAuth from "../Hooks/useAuth";
import { rcsRequest } from "../utils/authConfig";

const RcsApi = () => {
  const { passTokenToApi } = useAuth();
  return {
    generateShortlink: async (url) => {
      const accessToken = await passTokenToApi(rcsRequest);
      try{
        
        var raw = JSON.stringify({
          url: url
        });

        if(accessToken) {
            var myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${accessToken}`);
            var requestOptions = {
                method: 'POST',
                body: raw,
                headers: myHeaders,
            };
            const resource = "/api/Shortlink/Preview";
            const url = `${process.env.REACT_APP_SHORTLINK_API_URL}${resource}`;
        
            let response = await fetch(url, requestOptions);
            if (response.status !== 200) {
              throw new Error("An error occurred generate shortlink.");
            }
            let shortlink = await response.text();
            return shortlink;
        }
        else {
            console.log('Authentication Error');
            return [];
        }
      }catch (error) {
          console.error(error);
          throw new Error("An error occurred generate shortlink.");
      }
    },
    sendCampaign: async (campaign) => {
      const accessToken = await passTokenToApi(rcsRequest);
      try {
        if (accessToken) {
          var myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");
          myHeaders.append("Authorization", `Bearer ${accessToken}`);

          var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify(campaign)
          };

          const resource = "/api/SendCampaign";
          const url = `${process.env.REACT_APP_RCS_API_URL}${resource}`;

          let response = await fetch(url, requestOptions);

          if (response.status !== 200) {
            console.error(await response.text());
            throw new Error("An error occurred sending the campaign");
          }

          let result = await response.text();
          return result;
        }
      } catch (error) {
        console.error(error);
        throw new Error("An error occurred sending the campaign.");
      }
    },
    addDataFile: async (formData) => {
      const accessToken = await passTokenToApi(rcsRequest);
      try {
        if (accessToken) {
          var myHeaders = new Headers();
          myHeaders.append("Authorization", `Bearer ${accessToken}`);
          var requestOptions = {
            method: 'POST',
            body: formData,
            headers: myHeaders,
          };

          const resource = "/api/AddDataFile";
          const url = `${process.env.REACT_APP_RCS_API_URL}${resource}`;

          let response = await fetch(url, requestOptions);

          if (response.status === 400) {
            let result = await response.json();
            let listErrors = result.listErrors.map(x => `${x}\n`);
            throw new Error(`Errors in data file: \n ${listErrors}`);
          } else if (response.status !== 200){
            console.error(await response.text());
            throw new Error("An error occurred uploading the file.");
          }

          let result = await response.json();
          return result;
        }
        else {
          console.log('Authentication Error');
          return [];
        }
      } catch (error) {
        console.error(error);
        throw new Error(error.message);
      }
    },
    addDataFileWithContacts: async (formData) => {
      const accessToken = await passTokenToApi(rcsRequest);
      try {
        if (accessToken) {
          var myHeaders = new Headers();
          myHeaders.append("Authorization", `Bearer ${accessToken}`);
          var requestOptions = {
            method: 'POST',
            body: formData,
            headers: myHeaders,
          };

          const resource = "/api/AddDataFileContacts";
          const url = `${process.env.REACT_APP_RCS_API_URL}${resource}`;

          let response = await fetch(url, requestOptions);

          if (response.status !== 200) {
            console.error(await response.text());
            throw new Error("An error occurred uploading the file.");
          }

          let result = await response.json();
          return result;
        }
        else {
          console.log('Authentication Error');
          return [];
        }
      } catch (error) {
        console.error(error);
        throw new Error("An error occurred uploading the file.");
      }
    },
    getMessageCost: async (mobileNumber, message) => {
      let accessToken = await passTokenToApi(rcsRequest);
      if (accessToken) {
        const bearer = `Bearer ${accessToken}`;

        var myHeaders = new Headers();
        myHeaders.append('Authorization', bearer);
        myHeaders.append('Content-Type', 'application/json');

        var raw = JSON.stringify({
          "body": message,
          "to": mobileNumber
        });

        const options = {
          method: 'POST',
          headers: myHeaders,
          body: raw,
        };

        const resource = `/api/campaign/smscost`;
        const url = `${process.env.REACT_APP_RCS_API_URL}${resource}`;

        let response = await fetch(url, options);
        let mesmCost = await response.json();
        return mesmCost;
      } else {
        console.log("Authentication Error");
        return null;
      }
    },
    sendMessage: async (companyId, accountId, user, body) => {
      const accessToken = await passTokenToApi(rcsRequest);
      try {
        if (accessToken) {
          var myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");
          myHeaders.append("Authorization", `Bearer ${accessToken}`);

          var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify(body)
          };

          const resource = `/api/campaign/company/${companyId}/account/${accountId}/user/${user}/message`;
          const url = `${process.env.REACT_APP_RCS_API_URL}${resource}`;

          let response = await fetch(url, requestOptions);

          if (response.status !== 200) {
            console.error(await response.text());
            throw new Error("An error occurred sending the message");
          }

          let result = await response.text();
          return result;
        }
        else {
          console.log('Authentication Error');
          return [];
        }
      } catch (error) {
        console.error(error);
        throw new Error("An error occurred sending the message");
      }
    },
    schedule: async (campaignId, data) => {
      const accessToken = await passTokenToApi(rcsRequest);
      try {
        if (accessToken) {
          var myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");
          myHeaders.append("Authorization", `Bearer ${accessToken}`);
          var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify(data)
          };

          const resource = `/api/campaign/${campaignId}/scheduled`;
          const url = `${process.env.REACT_APP_RCS_API_URL}${resource}`;

          let response = await fetch(url, requestOptions);

          if (response.status !== 200) {
            console.error(await response.text());
            throw new Error("An error occurred generating the schedule");
          }

          let result = await response.text();
          return result;
        }
        else {
          console.log('Authentication Error');
          return [];
        }
      } catch (error) {
        console.error(error);
        throw new Error("An error occurred generating the schedule");
      }
    },
    campaigBudget: async (campaignId) => {
      const accessToken = await passTokenToApi(rcsRequest);
      try {
        if (accessToken) {
          var myHeaders = new Headers();
          myHeaders.append("Authorization", `Bearer ${accessToken}`);
          var requestOptions = {
            method: 'GET',
            headers: myHeaders,
          };

          const resource = `/api/Campaign/${campaignId}/Budget`;
          const url = `${process.env.REACT_APP_RCS_API_URL}${resource}`;

          let response = await fetch(url, requestOptions);

          if (response.status !== 200) {
            console.error(await response.text());
            throw new Error("An error occurred calculating campaign cost.");
          }

          let result = await response.json();
          return result;
        }
        else {
          console.log('Authentication Error');
          return null;
        }
      } catch (error) {
        console.error(error);
        throw new Error("An error occurred calculating campaign cost.");
      }
    },
    GetCampaignsByAccountId: async (accountId) => {
      const accessToken = await passTokenToApi(rcsRequest);
      try {
        if (accessToken) {
          var myHeaders = new Headers();
          myHeaders.append("Authorization", `Bearer ${accessToken}`);
          var requestOptions = {
            method: 'GET',
            headers: myHeaders,
          };

          const resource = `/api/campaign/account/${accountId}`;
          const url = `${process.env.REACT_APP_RCS_API_URL}${resource}`;

          let response = await fetch(url, requestOptions);

          if (response.status !== 200) {
            console.error(await response.text());
            throw new Error("An error occurred get campaigns bt account Id.");
          }

          let result = await response.json();
          return result;
        }
        else {
          console.log('Authentication Error');
          return null;
        }
      } catch (error) {
        console.error(error);
        throw new Error("An error occurred get campaigns bt account Id.");
      }
    },
    getDataFileByPefix: async (companyId, prefix) => {
      const accessToken = await passTokenToApi(rcsRequest);
      try {
        if (accessToken) {
          var myHeaders = new Headers();
          myHeaders.append("Authorization", `Bearer ${accessToken}`);
          var requestOptions = {
            method: 'GET',
            headers: myHeaders,
          };

          const resource = `/api/campaign/${companyId}/datafile/${prefix}`;
          const url = `${process.env.REACT_APP_RCS_API_URL}${resource}`;

          let response = await fetch(url, requestOptions);

          if (response.status !== 200) {
            console.error(await response.text());
            throw new Error("An error occurred get data file by prefix.");
          }

          let result = await response.json();
          return result;
        }
        else {
          console.log('Authentication Error');
          return null;
        }
      } catch (error) {
        console.error(error);
        throw new Error("An error occurred get data file by prefix.");
      }
    },
    deleteCampaign: async (companyId) => {
      const accessToken = await passTokenToApi(rcsRequest);
      try {
        if (accessToken) {
          var myHeaders = new Headers();
          myHeaders.append("Authorization", `Bearer ${accessToken}`);
          var requestOptions = {
            method: 'DELETE',
            headers: myHeaders,
          };

          const resource = `/api/campaign/${companyId}`;
          const url = `${process.env.REACT_APP_RCS_API_URL}${resource}`;

          let response = await fetch(url, requestOptions);

          if (response.status !== 200) {
            console.error(await response.text());
            throw new Error("An error occurred deleting campaign.");
          }

          let result = await response.text();
          return result;
        }
        else {
          console.log('Authentication Error');
          return null;
        }
      } catch (error) {
        console.error(error);
        throw new Error("An error occurred deleting campaign.");
      }
    }
  }
}




export default RcsApi ;