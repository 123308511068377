import { Alert, Col, Form, Input, Row, Select } from "antd";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import ShortlinkApi from "../../../Api/ShortlinkApi";
import { getGradientColor, purpleColor } from "../../../utils/colorsCss";
import CONSTANTS from "../../../utils/const";
import encodingSevenBits from "../../../utils/helpers";
import HelperButton from "../../HelperButton/HelperButton";
import styles from "./ContentMessage.module.css";
import Button from "../../Button/Button";
import ModalConfirmation from "../../ModalConfirmation/ModalConfirmation";

const { Option } = Select;

const ContentMessage = ({customFields,
  defaultSenderId,
  defaultUrl,
  onChangeTemplate,
  onChangeWildCards,
  onSaveTemplate}) => {

  const [form] = Form.useForm();

  const [t] = useTranslation("whatsapp-campaign");

  const [senderId, setSenderId] = useState(defaultSenderId.length > 0 ? defaultSenderId : "");
  const [url, setUrl] = useState(defaultUrl.length > 0 ? defaultUrl : "");
  const [hasMessage, setHasMessage] = useState(false);
  const [modalTemplateVisible, setModalTemplateVisible] = useState(false);
  const [showTemplateNameValidation, setShowTemplateNameValidation] = useState(false);
  const [templateName, setTemplateName] = useState("");
  const [templateSelected, setTemplateSelected] = useState(null);
  

  const handleOkTemplate = () => {
    if(!templateName) {
      setShowTemplateNameValidation(true);
      return;
    }

    handleCancelTemplate();
    onSaveTemplate(templateName);
  }

  const handleCancelTemplate = () => {
    setModalTemplateVisible(false);
    setShowTemplateNameValidation(false);
    setTemplateName("");
    form.resetFields();
  }

  const handleTemplateNameChange = (e) => {
    let value = e.target.value;
    if(value) {
      setShowTemplateNameValidation(false);
      setTemplateName(value);
    }
  }

  const onHandlerTemplate = (value) => {
    let result = CONSTANTS.LIST.WHATSAPPTEMPLATES.find(x => x.key === value);
    setTemplateSelected(result);
    onChangeTemplate(value)
  }

  return (
    <>
      <div className={styles['message-form']}>
        <div>
          <Form
            layout="vertical"
          >
            <Form.Item label={"Whatsapp Templates"}>
              <Row>
                <Col>
                  <Select
                    placeholder="Select Template"
                    style={{ width: 500 }}
                    onChange={onHandlerTemplate}
                  >
                    {
                      CONSTANTS.LIST.WHATSAPPTEMPLATES.map(item =>
                        <Option key={item.key} value={item.key}>{t(item.value)}</Option>
                      )
                    }
                  </Select>
                </Col>
              </Row>
            </Form.Item>
          </Form>
        </div>
        <div className={styles['message-sedner-toltip']}>
          <HelperButton 
            message={t("content-message.tooltip-sender-id")}
            color={getGradientColor(purpleColor)}
          />
        </div>
      </div>
      <div className={styles['message-form']}>
        <div>
          <Form
            layout="vertical"
          >
            <Form.Item label={"Wildcard"}>
              <Row>
                <Col>
                  <Select
                    disabled={!templateSelected}
                    placeholder="Select Wildcard"
                    style={{ width: 500 }}
                    onChange={onChangeWildCards}
                  >
                    {
                      customFields.map(item =>
                        <Option key={item} value={item}>{t(item)}</Option>
                      )
                    }
                  </Select>
                </Col>
              </Row>
            </Form.Item>
          </Form>
        </div>
        <div className={styles['message-sedner-toltip']}>
          <HelperButton 
            message={t("content-message.tooltip-url")}
            color={getGradientColor(purpleColor)}
          />
        </div>
      </div>
      <div className={styles['save-template']}>
        <Button type="primary" 
          onClick={() => setModalTemplateVisible(true)}
          disabled={senderId.length == 0 || url.length == 0 || !hasMessage}
        >
          {t("content-message.button-save-template")}
        </Button>
      </div>
      <ModalConfirmation
        visible={modalTemplateVisible}
        title={t("content-message.modal-template-title")}
        onCancel={handleCancelTemplate}
        onOk={handleOkTemplate}
        okBtnText={t("content-message.modal-template-btn-ok")}
      >
        <Form
          data-testid="content"
          name="validate_other"
          form={form}
          layout="vertical"
        >
          <Row>
            <Col>
              <p>{t("content-message.modal-template-content")}</p>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Item
                name="TemplateName"
                label={t("content-message.modal-template-label-name")}
                extra={
                  (
                    showTemplateNameValidation && (
                      <Alert message={t("content-message.modal-template-required-field")} type="error" showIcon />
                    )
                  )
                }
              >
                <Input
                  id="TemplateName"
                  name="TemplateName"
                  style={{ width: 320 }}
                  placeholder={t("content-message.modal-template-placeholder-name")}
                  onChange={handleTemplateNameChange}
                  value={templateName}
                />                
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </ModalConfirmation>
    </>
  );
}
 
export default ContentMessage;