const TYPES = {
  SPINNER: {
    SHOW: "SHOWSPINNER"
  },
  BREADCRUMB: {
    SELECT_STEP: "SELECT_STEP"
  },
  CAMPAIGN: {
    ADD_CAMPAINGNAME: "ADD_CAMPAINGNAME",
    ADD_ACCOUNT: "ADD_ACCOUNT",
    ADD_FILE: "ADD_FILE",
    ADD_FILEDATA: "ADD_FILEDATA",
    ADD_FILEHEADERS: "ADD_FILEHEADERS",
    ADD_MOBILENUMBERCOLUMN: "ADD_MOBILENUMBERCOLUMN",
    ADD_CUSTOMEFIELDS: "ADD_CUSTOMEFIELDS",
    ADD_SELECTEDHEADERS: "ADD_SELECTEDHEADERS",
    ADD_SENDERID: "ADD_SENDERID",
    ADD_MESSAGE: "ADD_MESSAGE",
    ADD_WILDCARDS: "ADD_WILDCARDS",
    ADD_FILEROWS: "ADD_FILEROWS",
    ADD_CAMPAIGNID: "ADD_CAMPAIGNID",
    ADD_SCHEDULE: "ADD_SCHEDULE",
    ADD_SUMMARY: "ADD_SUMMARY",
    ADD_URL: "ADD_URL",
    ADD_SHORTLINK: "ADD_SHORTLINK",
    LOAD_CONTACTS: "LOAD_CONTACTS",
    ADD_CONTACT: "ADD_CONTACT",
    ADD_VOICE: "ADD_VOICE",
    ADD_TEMPLATES: "ADD_TEMPLATES",
    SELECT_TEMPLATE: "SELECT_TEMPLATE"
  },
  HEADER_OPTIONS: {
    DEFAULT_OPTION: "DEFAULT_OPTION",
    SELECT_ADMIN_RATES_CHANGES_OPTION: "SELECT_ADMIN_RATES_CHANGES_OPTION",
    SELECT_ADMIN_RATES_CHANGES_MODEL: "SELECT_ADMIN_RATES_CHANGES_MODEL",
    ADD_ADMIN_RATES_CHANGES_OPTION: "ADD_ADMIN_RATES_CHANGES_OPTION",
    APLY_ADMIN_RATES_CHANGES_OPTION: "APLY_ADMIN_RATES_CHANGES_OPTION",
    ADD_ADMIN_RATES_CHANGES_MODEL: "ADD_ADMIN_RATES_CHANGES_MODEL",
    CONFIRM_ADMIN_RATES_CHANGES_OPTION: "CONFIRM_ADMIN_RATES_CHANGES_OPTION",
    CONFIRM_ADMIN_RATES_CHANGES_MODEL: "CONFIRM_ADMIN_RATES_CHANGES_MODEL",
    SELECT_COMPANY_RATES_CHANGES_OPTION: "SELECT_COMPANY_RATES_CHANGES_OPTION",
    SELECT_COMPANY_RATES_CHANGES_MODEL: "SELECT_COMPANY_RATES_CHANGES_MODEL",
    HISTORY_BALANCE_OPTION: "HISTORY_BALANCE_OPTION",
    HISTORY_BALANCE_MODEL: "HISTORY_BALANCE_MODEL",
    CAMPAIGN_HISTORY_OPTION: "CAMPAIGN_HISTORY_OPTION",
    CAMPAIGN_HISTORY_MODEL: "CAMPAIGN_HISTORY_MODEL"
  },
  RATES_PROCESS_STEP: {
    SELECT_STEP: "SELECT_STEP",
    ADD_STEP: "ADD_STEP",
    CONFIRM_STEP: "CONFIRM_STEP",
    DONE_STEP: "DONE_STEP",
    RELOAD_STEP: "RELOAD_STEP"
  },
  CONTACTS: {
    ADD_LISTGROUP_NAME: "ADD_LISTGROUP_NAME",
    ADD_ACCOUNTS: "ADD_ACCOUNTS",
    ADD_FILE: "ADD_FILE",
    ADD_FILEDATA: "ADD_FILEDATA",
    ADD_FILEHEADERS: "ADD_FILEHEADERS",
    ADD_FILEROWS: "ADD_FILEROWS",
    ADD_SELECTEDHEADERS: "ADD_SELECTEDHEADERS",
    ADD_MOBILENUMBERCOLUMN: "ADD_MOBILENUMBERCOLUMN",
    ADD_CUSTOMEFIELDS: "ADD_CUSTOMEFIELDS"
  }
}

export default TYPES;