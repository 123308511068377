import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import BalanceApi from "../../Api/BalanceApi";
import TableBodyCustom from "../../components/TableCustom/TableBodyCustom/TableBodyCustom";
import TableBodyTrCustom from "../../components/TableCustom/TableBodyTrCustom/TableBodyTrCustom";
import TableCustom from "../../components/TableCustom/TableCustom";
import TableHeadCustom from "../../components/TableCustom/TableHeadCustom/TableHeadCustom";
import { AuthContext } from "../../contexts/authRedirect";
import useHeaderOptions from "../../Hooks/useHeaderOptions";
import useSpinner from "../../Hooks/useSpinner";
import TYPES from "../../reducers/types";
import CONSTANTS from "../../utils/const";
import { dateFormat } from "../../utils/helpers";
import { Pagination, Select } from "antd";
import styles from "./HistoryBalanceCarrier.module.css";

const { Option } = Select;

const HistoryBalanceCarrier = () => {
  const [historyBalanceCarrierData, setHistoryBalanceCarrierData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const { auth } = useContext(AuthContext);
  const { headerOptionsDispacth, headerOptionsState } = useHeaderOptions();
  const { spinnerDispacth } = useSpinner();
  const { getHistorybalanceBalance } = BalanceApi();
  const [t] = useTranslation("history-balance");

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    fetchData();
  }, [headerOptionsState.model.selectedDate]);

  const fetchData = async () => {
    spinnerDispacth({
      type: TYPES.SPINNER.SHOW,
      payload: { show: true, item: CONSTANTS.SPINNERITEM.HISTORYBALANCE }
    });
    try {
      let result = await getHistorybalanceBalance();
      const selectedDate = headerOptionsState.model.selectedDate;

      if (selectedDate) {
        switch (selectedDate) {
          case "Year":
            result = result.filter(balance => {
              const balanceYear = new Date(balance.transferDate).getFullYear();
              return balanceYear === new Date().getFullYear();
            });
            break;
          case "Month":
            result = result.filter(balance => {
              const balanceMonth = new Date(balance.transferDate).getMonth();
              const currentMonth = new Date().getMonth();
              return balanceMonth === currentMonth;
            });
            break;
          case "Week":
            const startOfWeek = new Date();
            startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay());
            const endOfWeek = new Date(startOfWeek);
            endOfWeek.setDate(endOfWeek.getDate() + 6);
            result = result.filter(balance => {
              const transferDate = new Date(balance.transferDate);
              return transferDate >= startOfWeek && transferDate <= endOfWeek;
            });
            break;
          default:
            break;
        }
      }
      // Ordenar los resultados por la fecha de transferencia de manera descendente (más reciente primero)
      result.sort((a, b) => new Date(b.transferDate) - new Date(a.transferDate));
      setHistoryBalanceCarrierData(result);
    } catch (error) {
      console.error(error);
    } finally {
      spinnerDispacth({
        type: TYPES.SPINNER.SHOW,
        payload: { show: false, item: CONSTANTS.SPINNERITEM.HISTORYBALANCE }
      });
    }
  };

  const handlePaginationChange = (page) => {
    setCurrentPage(page);
  };

  const handlePeriodChange = (value) => {
    headerOptionsDispacth({
      type: TYPES.HEADER_OPTIONS.HISTORY_BALANCE_MODEL,
      payload: { selectedDate: value }
    });
  };

  const filteredData = () => {
    return historyBalanceCarrierData;
  };

  const dataToDisplay = filteredData().slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  return (
    <div className={styles.contain}>
      <div className={styles.adminHeaderTable}>
        <div className={styles.filters}>
          <p className={styles.labelFilter}>{t("filter")}</p>
          <Select
            className={styles.selectFilter}
            onChange={handlePeriodChange}
            value={headerOptionsState.model.selectedDate || ""}
            placeholder="Year / Month / Week"
            style={{ width: 200 }}
          >
            <Option value="">{t("filter-Option")}</Option>
            <Option value="Year">{t("filter-Year")}</Option>
            <Option value="Month">{t("filter-Month")}</Option>
            <Option value="Week">{t("filter-Week")}</Option>
          </Select>
        </div>
        <div className={styles.pagination}>
          <Pagination
            current={currentPage}
            onChange={handlePaginationChange}
            total={filteredData().length}
            pageSize={itemsPerPage}
            showSizeChanger={false}
          />
        </div>
      </div>
      <div className={styles.tableContainer}>
        <TableCustom>
          <TableHeadCustom>
            <th>User</th>
            <th>Company Target</th>
            <th>Amount</th>
            <th>Transfer Date</th>
          </TableHeadCustom>
          <TableBodyCustom>
            {dataToDisplay.length > 0 ? (
              dataToDisplay.map(balance => (
                <TableBodyTrCustom key={balance.id}>
                  <td>{balance.user}</td>
                  <td>{balance.company}</td>
                  <td>{balance.amount}</td>
                  <td>{dateFormat(balance.transferDate)}</td>
                </TableBodyTrCustom>
              ))
            ) : (
             null
            )}
          </TableBodyCustom>
        </TableCustom>
      </div>
    </div>
  );
};

export default HistoryBalanceCarrier;
