import React, { useState, useEffect, useContext } from 'react';
import CardFilter from "../../CardFilter/CardFilter";
import CardCreate from "../../CardCreate/CardCreate";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEye } from '@fortawesome/pro-solid-svg-icons'
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { Input, Row, Col } from 'antd';
import accountApi from "../../../Api/AccountApi";
import BalanceApi from "../../../Api/BalanceApi";
import { filterByField } from "../../../utils/helpers";
import AccountForm from "../AccountForm/accountform";
import UserApi from "../../../Api/UserApi";
import { notificationOk, notificationError, dateFormat } from '../../../utils/helpers';
import { AuthContext } from "../../../contexts/authRedirect";
import CardCustom from '../../CardCustom/CardCustom';
import accountsCreateImg from '../../../assets/resources/icono-account-01.png'
import styles from "./companyaccounts.module.css";
import CONSTANTS, { CONSTANPERIODREPORTLIST, CONSTANTUSERTYPE } from "../../../utils/const";
import { useTranslation } from 'react-i18next'
import TYPES from '../../../reducers/types';
import useSpinner from '../../../Hooks/useSpinner';
import ModalConfirmation from "../../ModalConfirmation/ModalConfirmation";
import AccountEdit from "../AccountCardEdit/accountcard-edit";
import { v4 as uuidv4 } from "uuid";

const CompanyAccounts = ({ onAccountSelected, accountsData }) => {
const {Search} = Input;
  const [t] = useTranslation("account");
  const [accounts, setAccountsState] = useState([]);
  const [accountsFiltered, setAccountsFilteredState] = useState([]);
  const [showCreateAccount, setShowCreateAccount] = useState(false);
  const [cardSelect, setCardSelect] = useState({});

  const { auth } = useContext(AuthContext);
  const { spinnerDispacth } = useSpinner();
  const { getUsersByCompany, UpdateCustomAttributes, getUserData } = UserApi();

  const [modalMessage, setModalMessage] = useState("");
  const [modalAccountVisible, setModalAccountVisible] = useState(false);
  const [accountById, setAccountById] = useState({});
  const [accountEditById, setAccountEditById] = useState({});
  const [showEditAccount, setShowEditAccount] = useState(false);
  const [showAllUsers, setShowAllUsers] = useState([]);

  useEffect(() => {
    spinnerDispacth({
      type: TYPES.SPINNER.SHOW,
      payload: { show: true, item: CONSTANTS.SPINNERITEM.COMPANYACCOUNTS }
    });

    if(accountsData.length > 0) {      
      getInitialData()
      .then(() => {
        
        spinnerDispacth({
          type: TYPES.SPINNER.SHOW,
          payload: { show: false, item: CONSTANTS.SPINNERITEM.COMPANYACCOUNTS }
        });

      })
    }
  }, [accountsData]);

  const { SetAccount, PutAccount, UpdateAccount, GetAccountByAccountId } = accountApi();
  const { SetAccountBalance } = BalanceApi();
  const { getUsersByIds } = UserApi();

  const getInitialData = async () => {

    try {
      const userData = await getUsersByCompany(auth.companyId);
      setShowAllUsers(userData);
      
      let accountByUser = [];

      if(auth.role === CONSTANTS.ROLES.COMPANY_ADMIN) {
        accountByUser = accountsData.filter(x=> x.enabled === true)
      } else {
        accountByUser = accountsData.filter(x => auth.userAccounts.includes(x.accountId) && x.enabled === true);
      }

      setAccountsState(accountByUser);
      setAccountsFilteredState(accountByUser);

      let userIdByAccounts = [];
      accountByUser.forEach(user => {
        user.accountUsers.forEach(userId => {
          userIdByAccounts.push(userId);
        });
      })
      
      userIdByAccounts = [...new Set(userIdByAccounts)]; //Delete duplicate elements

      let users = [];
      userIdByAccounts.forEach(userId => {
        let user = userData.find(x => x.id === userId);
        if(user != undefined)
          users.push(user);
      });

      let cards = {};
      accountByUser.forEach(ac => {
        cards = {...cards, [ac.accountId]: false};
      })

      setCardSelect(cards);
    }
    catch (error) {
      console.error(error);
    }
  }

  const isCardSelected = id => {
    return cardSelect[id];
  }

  const onChekedAccount = id => {

    if (accountsFiltered.length === 0) {
      onAccountSelected(null); // Limpiar selección de cuenta si no hay cuentas habilitadas
    }
    
    spinnerDispacth({
      type: TYPES.SPINNER.SHOW,
      payload: { show: true, item: CONSTANTS.SPINNERITEM.ACCOUNTMETRICS }
    });

    let cards = {};
    accountsFiltered.forEach(ac => {
      let value = ac.accountId === id;
      cards = {...cards, [ac.accountId]: value};
    });

    setCardSelect(cards);

    let account = accounts.find(x => x.accountId === id);
    getUsersByIds(account).then((usersByIds) => {
      spinnerDispacth({
        type: TYPES.SPINNER.SHOW,
        payload: { show: false, item: CONSTANTS.SPINNERITEM.ACCOUNTMETRICS }
      });

      const accountMetrics = {
        allUsersByAccount: usersByIds
      };

      let principalUser = auth.userId
      onAccountSelected({accountMetrics, account, principalUser});
    }).catch(error => {
      console.error(error);
      spinnerDispacth({
        type: TYPES.SPINNER.SHOW,
        payload: { show: false, item: CONSTANTS.SPINNERITEM.ACCOUNTMETRICS }
      });
      errorAccountProcess("An error occurred selected the users. Please contact to support team");
    });
  }

  const changeFilter = e => {
    let value = e.target.value;
    let result = filterByField(accounts, "name", value);
    setAccountsFilteredState(result.filter(x=> x.enabled === true));
  }

  const showDrawer = value => {
    setShowCreateAccount(value);
  }

  const onCreateAccount = async (account) => {
    try {
      let userPrincipal = auth.userId;
      spinnerDispacth({
        type: TYPES.SPINNER.SHOW,
        payload: { show: true, item: CONSTANTS.SPINNERITEM.CREATEACCOUNT }
      });

      let { name, description, users } = account;

      users = users || [];
      users.push(userPrincipal);

      let newAccount = {
        "companyId": auth.companyId,
        "name": name,
        "description": description,
        "accountUsers": users
      }

      let responseAccountApi = await SetAccount(newAccount, auth.accessToken);
      
      for (const userId of users) {
        let userData = await getUserData(userId);
        if (!userData.accounts.includes(responseAccountApi.accountId)) {
          userData.accounts.push(responseAccountApi.accountId);
        }
        const updateData = {
          UserId: userId,
          Accounts: userData.accounts
        };
        await UpdateCustomAttributes(updateData); 
      }

      await createAccountBalance(auth.companyId, responseAccountApi.accountId);
      showDrawer(false);
      notificationOk("The account was created successfully");
      setAccountsFilteredState([...accountsFiltered, responseAccountApi]);
      setAccountsState([...accounts, responseAccountApi]);

      spinnerDispacth({
        type: TYPES.SPINNER.SHOW,
        payload: { show: false, item: CONSTANTS.SPINNERITEM.CREATEACCOUNT }
      });

      return responseAccountApi;
    }
    catch (error) {
      spinnerDispacth({
        type: TYPES.SPINNER.SHOW,
        payload: { show: false, item: CONSTANTS.SPINNERITEM.CREATEACCOUNT }
      });
      console.error(error);
      errorAccountProcess("An error occurred creating the account. Please contact to support team");
    }
  }

  const createAccountBalance = async (companyId, accountId) => {
    try {
      let bodyAccount = {
        "CompanyId": companyId,
        "AccountId": accountId,
        "AvailableBalance": 0,
        "LastAddedBalanceDate": new Date().toUTCString(),
      }

      let response = await SetAccountBalance(bodyAccount, auth.accessToken)
      return response;

    } catch (error) {
      console.error(error);
      throw new Error("An error occurred creating the accotun balance. Please contact to support team");
    }
  }

  const errorAccountProcess = (message) => {
    showDrawer(false);
    notificationError(message);
  }

  const onEliminatedAccount = account => {
    let message = t("eliminated-account.button-submit-confirm");
    setModalMessage(message);
    setAccountById(account)
    setModalAccountVisible(true);
  }

  const handleConfirmModal = async () => {
    let account = accountById;
    let accountIdDefault = accountsData.filter(account => account.isDefaultAccount === true);
    let principalAccount = accountIdDefault[0].accountId;

    spinnerDispacth({
      type: TYPES.SPINNER.SHOW,
      payload: { show: true, item: CONSTANTS.SPINNERITEM.USERS }
    });

    if(account.accountId === principalAccount){
      spinnerDispacth({
        type: TYPES.SPINNER.SHOW,
        payload: { show: false, item: CONSTANTS.SPINNERITEM.USERS }
      });
      setModalAccountVisible(false);
      onAccountSelected(null);
      notificationError(t('eliminated-account.validate-principal-account'))
    }else{

      let accountDisabled = {
        "AccountId": account.accountId,
        "Name": account.name,
        "Description": account.description,
        "AccountUsers": account.accountUsers,
        "Enabled": false
      }
  
      try {

        // Obtained actual users of account
        let getAccountById = await GetAccountByAccountId(account.accountId);
        const currentUsers = getAccountById.accountUsers;
         
        // update account by users not exist on user
        for (const userId of currentUsers) {
          let userData = await getUserData(userId);
          
          if (userData.accounts.includes(account.accountId)) {
            userData.accounts = userData.accounts.filter(accountId => accountId !== account.accountId);
            const updateData = {
              UserId: userId,
              Accounts: userData.accounts
            };
            await UpdateCustomAttributes(updateData);
          }
        }

        await PutAccount(accountDisabled, principalAccount, auth.companyId);
        const updatedAccounts = accounts.map(acc =>
          acc.accountId === account.accountId ? { ...acc, enabled: false } : acc
        );
  
        setAccountsState(updatedAccounts);
        setAccountsFilteredState(updatedAccounts.filter(x => x.enabled === true));
  
        spinnerDispacth({
          type: TYPES.SPINNER.SHOW,
          payload: { show: false, item: CONSTANTS.SPINNERITEM.USERS }
        });
        notificationOk(t('eliminated-account.button-success'))
        setModalAccountVisible(false);
        onAccountSelected(null);
      } catch (err) {
        spinnerDispacth({
          type: TYPES.SPINNER.SHOW,
          payload: { show: false, item: CONSTANTS.SPINNERITEM.USERS }
        });
        notificationError(t('eliminated-account.button-error') + err)
      }
    }
  }

  const showDrawerEdit = (value, account) => {
    setShowEditAccount(value);
    setAccountEditById(account);
  };

  const onEditAccount = async (updatedAccount) => {
    try {

      let userIdPrincipal = auth.userId;
      let accountIdDefault = accountsData.filter(account => account.isDefaultAccount === true);
      let principalAccount = accountIdDefault[0].accountId;

      // Obtained actual users of account
      let getAccountById = await GetAccountByAccountId(updatedAccount.accountId);
      const currentUsers = getAccountById.accountUsers;

      // update users by account not exist on account
      for (const userId of currentUsers) {
        if (!updatedAccount.accountUsers.includes(userId)) {
          let userData = await getUserData(userId);
          userData.accounts = userData.accounts.filter(accountId => accountId !== updatedAccount.accountId);
          const updateData = {
            UserId: userId,
            Accounts: userData.accounts
          };
          await UpdateCustomAttributes(updateData);
        }
      }

      if (!updatedAccount.accountUsers.includes(userIdPrincipal)) {
        updatedAccount.accountUsers.push(userIdPrincipal);
      }
      
      // add principal account to user if not present
      for (const userId of updatedAccount.accountUsers) {
        let userData = await getUserData(userId);
        
        if (!userData.accounts.includes(updatedAccount.accountId)) {
          userData.accounts.push(updatedAccount.accountId);
        }

        if (!userData.accounts.includes(principalAccount)) {
          userData.accounts.push(principalAccount);
        }
        
        const updateData = {
          UserId: userId,
          Accounts: userData.accounts
        };
        await UpdateCustomAttributes(updateData);
      }
      await UpdateAccount(updatedAccount, updatedAccount.accountId);
      const updatedAccounts = accounts.map(acc =>
        acc.accountId === updatedAccount.accountId ? updatedAccount : acc
      );
      setAccountsState(updatedAccounts);
      setAccountsFilteredState(updatedAccounts.filter(x => x.enabled === true));
    } catch (error) {
      console.error(error);
      throw new Error("An error occurred updating the account. Please contact to support team");
    }
  }

  return (
    <CardFilter
      title={t("top-menu.title-top-menu-company")}
      filter={
        <Search
          placeholder={t("top-menu.placeholder-top-menu")}
          // onSearch={onSearch} 
          onChange={changeFilter}
        />
      }
      body={
        <div className="yp-grid-cards">
          <CardCreate
            title={t("account-card.title-card")}
            width="100%"
            onClick={() => showDrawer(true)}
            content={t("account-card.description-card")}
          />
          {
            accountsFiltered.map(account => (
              <CardCustom
                isCheked={isCardSelected(account.accountId)}
                key={account.accountId}
                minWidth="250px"
                width="100%"
                extra={[
                  <div className={styles['card-buttons']} key={`buttons-${account.accountId}`}>
                    <div className={styles['right-buttons']} key={`right-buttons-${account.accountId}`}>
                      <a className={styles['fa-icon']} onClick={() => onChekedAccount(account.accountId)} key={`eye-${account.accountId}`} id={`faEye-icon-${uuidv4()}`}><FontAwesomeIcon icon={ faEye } /></a>
                    </div>
                    <a className={styles['fa-icon']} onClick={() => onEliminatedAccount(account)} key={`trash-${account.accountId}`} id={`trash-icon-${uuidv4()}`}>
                      <FontAwesomeIcon icon={faTrash} />
                    </a>
                    <a className={styles['fa-icon']} onClick={() => showDrawerEdit(true, account)} key={`edit-${account.accountId}`} id={`faPenToSquare-icon-${uuidv4()}`}>
                      <FontAwesomeIcon icon={faPenToSquare} />
                    </a>
                  </div>
                ]}
              >
                <Row>
                  <Col span={24}>
                    <img className={styles['img']} src={accountsCreateImg} alt="spiner" width="80"/>
                    <p className={styles['yp-subtitle']}>{account.name}</p>
                    <p className={styles['detail']}>{account.description}</p>
                    <p className={styles['detail']}><strong>{t("account-card.label-date-card")}</strong> {dateFormat(account.creationDate)}</p>
                  </Col>
                </Row>
                
              </CardCustom>
            ))
          }
          <AccountEdit
            visible={showEditAccount}
            onClose={() => showDrawerEdit(false, null)}
            account={accountEditById}
            allUsers={showAllUsers}
            onEdit={onEditAccount}
          />
          <AccountForm
            visible={showCreateAccount}
            users={showAllUsers}
            onClose={() => showDrawer(false)}
            onCreate={onCreateAccount}
          />
          <ModalConfirmation
            visible={modalAccountVisible}
            title={t("eliminated-account.title")}
            onCancel={() => setModalAccountVisible(false)}
            onOk={handleConfirmModal}
            okBtnText={t("eliminated-account.button-submit")}
          >
          <p>{modalMessage}</p>
          </ModalConfirmation>
        </div>
        
      }
    />
  );
}

export default CompanyAccounts;