
import { b2cPolicies } from './policies'
import { balanceScopes,
  companyScopes,
  campaignScopes,
  campaignVozScopes,
  accountScopes,
  userScopes, 
  shortlinkScopes,
  rateScopes,
  configurationScopes, 
  dataScopes,
  templateScopes,
  textToSpeechScopes,
  whatsappScopes,
  rcsScopes,
  reportScopes,
} from './apiScopes'

/**
 * Configuration object to be passed to MSAL instance on creation. 
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 * For more details on using MSAL.js with Azure AD B2C, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/working-with-b2c.md 
 */

const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_B2C_CLIENT_ID, // This is the ONLY mandatory field; everything else is optional.
    authority: b2cPolicies.authorities.signUpSignIn.authority, // Choose sign-up/sign-in user-flow as your default.
    knownAuthorities: [b2cPolicies.authorityDomain], // You must identify your tenant's domain as a known authority.
    redirectUri: process.env.REACT_APP_REDIRECT_URI,
  },
  cache: {
    cacheLocation: "localStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO.
    storeAuthStateInCookie: false, // If you wish to store cache items in cookies as well as browser cache, set this to "true".
  },
};

/**
* Scopes you add here will be prompted for user consent during sign-in.
* By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
* For more information about OIDC scopes, visit:
* https://docs.microsoft.com/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
*/
const loginRequest = {
  scopes: ["openid", "profile"],
  //extraScopesToConsent: allScopes,
};

/**
* Scopes you add here will be used to request a token from Azure AD B2C to be used for accessing a protected resource.
* To learn more about how to work with scopes and resources, see: 
* https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
*/
const accountRequest = {
  scopes: accountScopes,
  forceRefresh: false // Set this to "true" to skip a cached token and go to the server to get a new token
};

const campaignRequest = {
  scopes: campaignScopes,
  forceRefresh: false // Set this to "true" to skip a cached token and go to the server to get a new token
};

const campaignVozRequest = {
  scopes: campaignVozScopes,
  forceRefresh: false // Set this to "true" to skip a cached token and go to the server to get a new token
};

const balanceRequest = {
  scopes: balanceScopes,
  forceRefresh: false // Set this to "true" to skip a cached token and go to the server to get a new token
};

const companyRequest = {
  scopes: companyScopes,
  forceRefresh: false // Set this to "true" to skip a cached token and go to the server to get a new token
};

const shortlinkRequest = {
  scopes: shortlinkScopes,
  forceRefresh: false // Set this to "true" to skip a cached token and go to the server to get a new token
};

const userRequest = {
  scopes: userScopes,
  forceRefresh: false // Set this to "true" to skip a cached token and go to the server to get a new token
};

const ratesRequest = {
  scopes: rateScopes,
  forceRefresh: false // Set this to "true" to skip a cached token and go to the server to get a new token
};

const configurationRequest = {
  scopes: configurationScopes,
  forceRefresh: false // Set this to "true" to skip a cached token and go to the server to get a new token
}

const dataRequest = {
  scopes: dataScopes,
  forceRefresh: false // Set this to "true" to skip a cached token and go to the server to get a new token
}

const templateRequest = {
  scopes: templateScopes,
  forceRefresh: false // Set this to "true" to skip a cached token and go to the server to get a new token
}

const textToSpeechRequest = {
  scopes: textToSpeechScopes,
  forceRefresh: false // Set this to "true" to skip a cached token and go to the server to get a new token
}

const whatsappRequest = {
  scopes: whatsappScopes,
  forceRefresh: false // Set this to "true" to skip a cached token and go to the server to get a new token
}

const rcsRequest = {
  scopes: rcsScopes,
  forceRefresh: false // Set this to "true" to skip a cached token and go to the server to get a new token
}
const reportRequest = {
  scopes: reportScopes,
  forceRefresh: false // Set this to "true" to skip a cached token and go to the server to get a new token
}

export { msalConfig,
  accountRequest,
  campaignRequest,
  campaignVozRequest,
  balanceRequest,
  companyRequest,
  loginRequest,
  userRequest,
  shortlinkRequest,
  ratesRequest,
  configurationRequest,
  dataRequest,
  templateRequest,
  textToSpeechRequest,
  whatsappRequest,
  rcsRequest,
  reportRequest
}