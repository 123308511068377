import { Alert, Col, Form, Row, Select, Input } from "antd";
import { Fragment, useState } from "react";
import { getGradientColor, purpleColor } from "../../../utils/colorsCss";
import HelperButton from "../../HelperButton/HelperButton";
import { useTranslation } from 'react-i18next'
import propTypes from 'prop-types'

const { Option } = Select;

const SelectAccount = ({accounts, onAccountChange, onCampaignNameChange, showValidations, defaultSelected, defaultCampaignName}) => {

  const [t] = useTranslation("rcs-campaign");

  const [ validationAccount , setValidationAccount ] = useState(true);
  const [ validationCampaignName , setValidationCampaignName ] = useState(true);

  const handleAccountChange = (value) => {
    setValidationAccount(false);
    onAccountChange(value);
  };

  const handleCampaignNameChange = (e) => {

    let value = e.target.value;

    if(value) {
      setValidationCampaignName(false);
      onCampaignNameChange(value);
    } else {
      setValidationCampaignName(true);
      onCampaignNameChange(null);
    }
  };


  return (
    <Fragment>
      <Form
        data-testid="content"
        name="validate_other"
        initialValues={{
          'CampaignName': defaultCampaignName,
          "Account": defaultSelected,
        }}
        layout="vertical"
      >
        
        <Row>
            <Col>
              <p>{t("account.title")}</p>
            </Col>
          </Row>
        <Row>
          <Col>
            <Form.Item
              name="Account"
              label={t("account.label-select-account")}
              extra={
                (
                  showValidations && validationAccount && (
                    <Alert message={t("account.required-field")} type="error" showIcon />
                  )
                )
              }
            >
              <Select
                style={{ width: 320 }}
                placeholder={t("account.place-holder-select-account")}
                onChange={handleAccountChange}
              >
                {
                  accounts.map(item =>
                    <Option key={item.id} value={item.id}>{item.name}</Option>
                  )
                }
              </Select>                
            </Form.Item>
          </Col>
          <Col>
            <div style={{paddingTop: "34px"}}>
              <HelperButton
                message={t("account.tooltip-account")}
                color={getGradientColor(purpleColor)}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Item 
              name="CampaignName"
              label={t("account.label-text-campaign-name")}
              extra={
                (
                  showValidations && validationCampaignName && (
                    <Alert message={t("account.required-field")} type="error" showIcon />
                  )
                )
              }
            >
              <Input
                style={{ width: 320 }}
                placeholder={t("account.place-holder-text-campaign-name")}
                onChange={handleCampaignNameChange}
                value={defaultCampaignName}
              />
            </Form.Item>
          </Col>
          <Col>
            <div style={{paddingTop: "34px"}}>
              <HelperButton
                message={t("account.tooltip-campaign-name")}
                color={getGradientColor(purpleColor)}
              />
            </div>
          </Col>
        </Row>
      </Form>
    </Fragment>
  );
}

SelectAccount.propTypes = {
  accounts: propTypes.array.isRequired,
  onChange: propTypes.func,
  showValidations: propTypes.bool
}
 
export default SelectAccount;