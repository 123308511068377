import { getGradientColor, purpleColor } from "../../../../utils/colorsCss";
import { currencyFormat, getMonthByDate } from "../../../../utils/helpers";
import HelperButton from "../../../HelperButton/HelperButton";
import styles from "./AssignedCredit.module.css";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useEffect } from "react";
import { Spin } from "antd";
import { CONSTANTUSERTYPE } from "../../../../utils/const";
import { useContext } from "react";
import { AuthContext } from "../../../../contexts/authRedirect";
import DataApi from "../../../../Api/DataApi";

const AssignedCredit = () => {

  const [t] = useTranslation("home-company");
  const {getBalanceCompany} = DataApi();
  const { auth } = useContext(AuthContext);

  const [value, setValue] = useState(0);
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    getInitialData()
    .then(response => {
      const {assignedCreditValue} = response;
      setValue(assignedCreditValue);
      setLoading(false);
    })
    .catch(error => {
      console.error(error);
      setLoading(false);
    })
  }, [])

  const getInitialData = async () => {
    let response = await getBalanceCompany(CONSTANTUSERTYPE.COMPANY.key, auth.companyId);
    return { assignedCreditValue: response.totalBalance }
  }

  return (
    <div className={styles['dashboard-header-spinner']}>
      <Spin spinning={loading}>
        <div className={styles['dashboard-header-card']}>
          <div className={styles['dashboard-header-card-title']}>
            <p>{t("assigned-credit.title")}</p>
            <HelperButton
              message={t("assigned-credit.tootltip-title")}
              color={getGradientColor(purpleColor)}
            />
          </div>
          <div className={styles['dashboard-header-card-value']}>
            <p>{currencyFormat(value)} USD</p>
          </div>
          <div className={styles['dashboard-header-card-footer']}>
            <p>{getMonthByDate(new Date())}</p>
          </div>      
        </div>
      </Spin>
    </div>
  );
}
 
export default AssignedCredit;