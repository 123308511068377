import { faCommentAltLines, faHashtag, faStream, faTypewriter, faWallet } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Popconfirm, Form, Row, Col, Input } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./TestMessage.module.css";
import useSpinner from '../../../Hooks/useSpinner';
import TYPES from "../../../reducers/types";
import CONSTANTS from "../../../utils/const";
import CampaignApi from "../../../Api/CampaignApi";
import useAuth from "../../../Hooks/useAuth";
import encodingSevenBits, { currencyFormat, notificationError, notificationOk, notificationWarning } from "../../../utils/helpers";
import { getGradientColor, purpleColor } from "../../../utils/colorsCss";
import HelperButton from "../../HelperButton/HelperButton";
import ReactCountryFlag from "react-country-flag"
import { useEffect } from "react";

const TestMessage = ({message,
  accountId,
  senderId,
  customFields,
  fileHeaders,
  fileData,
  defualtShortLink}) => {

  useEffect(() => {
    if(message) {
      getMessage();
    }
  }, [message])

  const [t] = useTranslation("campaign-bulk");
  const { auth } =  useAuth();
  const [form] = Form.useForm();
  const { spinnerDispacth } = useSpinner();

  const [messagePartsTest, setMessagePartsTest] = useState([]);
  const [isValidateMessage, setIsValidateMessage] = useState(true);
  const [destination, setDestination] = useState(null);
  const [messageTest, setMessageTest] = useState(null);
  const [costMessageTest, setCostMessageTest] = useState(0);
  const [isBasicEncoding, setIsBasicEncoding] = useState(true);
  const [messageCount, setMessageCount] = useState(0);
  const [charUsed, setCharUsed] = useState(0);
  const [alertMessageCount, setAlertMessageCount] = useState(false);

  const { sendMessage, getMessageCost } = CampaignApi();

  const testMessage = () => {
    form.validateFields()
      .then(values => {
        spinnerDispacth({
          type: TYPES.SPINNER.SHOW,
          payload: { show: true, item: CONSTANTS.SPINNERITEM.VALIDATEMESSAGECAMPAIGN }
        });

        const { mobileNumber } = values;

        sendMessage(auth.companyId, accountId, auth.userName, {
          body: messageTest,
          to: mobileNumber,
          from: senderId.length > 0 ? senderId : "1234"
        }, auth.accessToken).then(response => {
          form.resetFields();
          
          spinnerDispacth({
            type: TYPES.SPINNER.SHOW,
            payload: { show: false, item: CONSTANTS.SPINNERITEM.VALIDATEMESSAGECAMPAIGN }
          });

          notificationOk(t("content-message.notification-ok-message"));
          setIsValidateMessage(true);
          setDestination(null);
          setCostMessageTest(0);
        }).catch(error => {
          console.error(error);
          
          spinnerDispacth({
            type: TYPES.SPINNER.SHOW,
            payload: { show: false, item: CONSTANTS.SPINNERITEM.VALIDATEMESSAGECAMPAIGN }
          });

          notificationError(t("content-message.notification-error-message"));
        });

      });
  }

  const validateMessage = () => {
    form.validateFields()
      .then(values => {

        spinnerDispacth({
          type: TYPES.SPINNER.SHOW,
          payload: { show: true, item: CONSTANTS.SPINNERITEM.VALIDATEMESSAGECAMPAIGN }
        });

        const { mobileNumber } = values;
        const firstRow = fileData[0].value;

        let msg = message;
        customFields.forEach(field => {
          if(field !== CONSTANTS.SHORTLINK) {
            let column = fileHeaders.find(x => x.name === field);
            let row = firstRow.find(x => x.column === column.column);
            msg = msg.replaceAll(`${CONSTANTS.WILDCARD}${field}${CONSTANTS.WILDCARD}`, row.value);
          }
        });

        getMessageCost(auth.companyId, mobileNumber, msg)
          .then(response => {
                        
            spinnerDispacth({
              type: TYPES.SPINNER.SHOW,
              payload: { show: false, item: CONSTANTS.SPINNERITEM.VALIDATEMESSAGECAMPAIGN }
            });
    

            setIsValidateMessage(false);
            setDestination({
              countryName: response.countryName,
              countryCode: response.countryCode,
              prefix: response.prefix
            });
            let cost = response.unitRate * response.totalMessage;
            setCostMessageTest(cost);
          }).catch(error => {
            spinnerDispacth({
              type: TYPES.SPINNER.SHOW,
              payload: { show: false, item: CONSTANTS.SPINNERITEM.VALIDATEMESSAGECAMPAIGN }
            });
            notificationError(t(error));
          })
      });
  }

  const getMessage = async () => {
    if(message.length === 1 && message == " ") {
      setMessagePartsTest([]);
      setMessageCount(0);
      setMessageTest("");
      setCharUsed(0);
    } else {
      const firstRow = fileData[0].value;

      let msg = message.replaceAll(`${CONSTANTS.WILDCARD}${CONSTANTS.SHORTLINK}${CONSTANTS.WILDCARD}`, defualtShortLink);
      customFields.forEach(field => {
          let column = fileHeaders.find(x => x.name === field);
          let row = firstRow.find(x => x.column === column.column);
          msg = msg.replaceAll(`${CONSTANTS.WILDCARD}${field}${CONSTANTS.WILDCARD}`, row.value);
      });

      let smsParts = getSmsParts(msg);

      let startPosition = 0;
      let endPosition = isGsm7Bit(msg) ? (msg.length > 160 ? 153 : 160) : (msg.length > 70 ? 67 : 70);
      let arrayMessages = [];

      for (let index = 0; index < smsParts; index++) {
        let newMessage = msg.substring(startPosition, endPosition);
        arrayMessages.push(newMessage);
        startPosition = endPosition;
        endPosition = isGsm7Bit(msg) ? (msg.length > 160 ? endPosition + 153 : endPosition + 160) : (msg.length > 70 ? endPosition + 67 : endPosition + 70);
      }

      setMessagePartsTest(arrayMessages);
      setMessageCount(arrayMessages.length);      

      if(arrayMessages.length > 1 && !alertMessageCount) {
        notificationWarning(t("content-message.warning-message-count"));
      }

      setAlertMessageCount(arrayMessages.length > 1);
      
      let ms = arrayMessages.join("");
      setMessageTest(ms);
      setCharUsed(ms.length);
      
      var isBasic = isBasicEncoding;

      let chart = ms.charAt(ms.length - 1);
      isBasic = encodingSevenBits.includes(chart) ? true : false;
      let hasSpecialCharacters = !isGsm7Bit(msg);

      if (isBasic && !hasSpecialCharacters) {
        setIsBasicEncoding(true);
      } else {
        setIsBasicEncoding(false);
      }
    }
  }

  const isGsm7BitChar = (char) => encodingSevenBits.includes(char);

  const isGsm7Bit = (message) => {
    for (let i = 0; i < message.length; i++) {
      if (!isGsm7BitChar(message[i])) {
        return false;
      }
    }
    return true;
  };

  const getSmsParts = (message) => {
    let maxCharsPerPart;
    let isGsm = isGsm7Bit(message);
  
    if (isGsm) {
      maxCharsPerPart = 160;
      if (message.length > 160) {
        maxCharsPerPart = 153; // Para mensajes concatenados.
      }
    } else {
      maxCharsPerPart = 70;
      if (message.length > 70) {
        maxCharsPerPart = 67; // Para mensajes concatenados UCS-2
      }
    }
  
    let parts = Math.ceil(message.length / maxCharsPerPart);
    return parts;
  };

  return (
    <div className={styles['test-message-content']}>
      <div className={styles['test-message-info']}>
        <div className={styles['message-information']}>
          <div className={styles['message-information-text']}>
            <div className={styles['message-information-text-title']}>
              <p>{t("content-message.label-encoding")} </p>
              <HelperButton 
                message={t("content-message.tooltip-encoding")}
                color={getGradientColor(purpleColor)}
              />
            </div>            
            <p className={styles['message-information-text-value']}>
              <FontAwesomeIcon style={{ marginRight: "5px" }} icon={faTypewriter} /> {isBasicEncoding ? "7 Bits" : "16 Bits"}
            </p>
          </div>
        </div>
        <div className={styles['message-information']}>
          <div className={`${isBasicEncoding ? styles['message-information-text'] : styles['message-information-text-red']}`}>
            <div className={styles['message-information-text-title']}>
              <p>{t("content-message.label-per-sms")}</p>
              <HelperButton 
                    message={t("content-message.tooltip-per-sms")}
                    color={getGradientColor(purpleColor)}
              />
            </div>
            <p className={styles['message-information-text-value']}>
              <FontAwesomeIcon style={{ marginRight: "5px" }} icon={faCommentAltLines} /> {isBasicEncoding ? (message.length > 160 ? "153" : "160") : (message.length > 70 ? "67" : "70")}
            </p>
          </div>
        </div>
        <div className={styles['message-information']}>
          <div className={styles['message-information-text']}>
            <div className={styles['message-information-text-title']}>
              <p>{t("content-message.label-chars")}</p>
              <HelperButton 
                message={t("content-message.tooltip-chars")}
                color={getGradientColor(purpleColor)}
              />
            </div>
            <p className={styles['message-information-text-value']}>
              <FontAwesomeIcon style={{ marginRight: "5px" }} icon={faHashtag} /> {charUsed}
            </p>
          </div>
        </div>
        <div className={styles['message-information']}>
          <div className={`${messageCount <= 1 ? styles['message-information-text'] : styles['message-information-text-orange']}`}>
            <div className={styles['message-information-text-title']}>
              <p>{t("content-message.label-parts")}</p>
              <HelperButton 
                message={t("content-message.tooltip-parts")}
                color={getGradientColor(purpleColor)}
              />
            </div>
            <p className={styles['message-information-text-value']}>
              <FontAwesomeIcon style={{ marginRight: "5px" }} icon={faStream} /> {messageCount}
            </p>
          </div>
        </div>
        {
          destination && (
            <div className={styles['message-information']}>
              <div className={styles['message-information-text']}>
                <p>{t("content-message.label-test-destination")}</p>
                <p className={styles['message-information-text-cuntry-value']}>
                  <ReactCountryFlag
                    countryCode={destination?.countryCode}
                    svg
                    style={{
                      width: '1.5em',
                      height: '1.5em',
                      marginRight: 5
                    }}
                    title={destination.countryCode}
                  />
                  +{destination?.prefix} {destination?.countryName}
                </p>
              </div>
            </div>
          )
        }
        {
          costMessageTest !== 0 && (
            <div className={styles['message-information']}>
              <div className={styles['message-information-text']}>
                <p>{t("content-message.label-test-message-cost")}</p>
                <p className={styles['message-information-text-value']}>
                  <FontAwesomeIcon style={{ marginRight: "5px" }} icon={faWallet} /> {currencyFormat(costMessageTest, 4)}
                </p>
              </div>
            </div>
          )
        }
      </div>
      <div className={styles['test-message-phone']}>
        <Form
          layout="vertical"
          form={form}
          initialValues={{
            initialState: false,
          }}
        >          
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="mobileNumber"
                label={t("content-message.label-test-mobile-number")}
                rules={[{ required: true, message: 'Please enter the mobile number' }]}
              >
                <Input
                  placeholder={t("content-message.label-test-mobile-number-placeholder")}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <div className={styles['smartphone-wrap']}>
                <div className={styles['smartphone']}>
                  <div className={styles['smartphone-screen']}>
                    {
                      messagePartsTest.map((msg, index) => (
                        <div id={`message_dinamic_${index}`} key={index} className={styles['smartphone-message']}>
                          <p>
                            {msg}
                          </p>
                        </div>
                      ))
                    }
                  </div>
                  <div className={styles['smartphone-content-button']}>
                  {
                    isValidateMessage ? (
                      <Button
                        type="secondary"
                        onClick={validateMessage}
                        style={{ width: 210 }}
                        disabled={messageTest && messageTest.length > 0 ? false : true}
                      >
                        {t("content-message.button-test-validate")}
                      </Button>
                    ) : (
                      <Popconfirm title={t("content-message.confirm-test")}
                        onConfirm={testMessage}
                        okText={t("content-message.confirm-test-button")}
                        cancelText={t("content-message.cancel-test-button")}
                      >
                        <Button
                          type="secondary"
                          style={{ width: 210 }}
                        >
                          {t("content-message.button-test-send")}
                        </Button>
                      </Popconfirm>
                    )
                  }
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
}
 
export default TestMessage;