import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter as Router } from 'react-router-dom';
import './index.css';
import App from './App';
import AuthProvider from "./contexts/authRedirect";
import * as serviceWorker from './serviceWorker';
import CurrencyProvider from './contexts/currencyContext';
import TimezoneProvider from './contexts/timezoneContext';
import BreadcrumbProvider from './contexts/breadcrumbContext';
import HeaderOptionProvider from './contexts/headerOptionContext';
import RatesAccountProvider from './contexts/ratesAccountContext';
import { I18nextProvider } from 'react-i18next'
import i18next from 'i18next'
import home_company_en from './traslations/en/home-company.json'
import home_company_es from './traslations/es/home-company.json'
import home_carrier_en from './traslations/en/home-carrier.json'
import home_carrier_es from './traslations/es/home-carrier.json'
import home_account_en from './traslations/en/home-account.json'
import home_account_es from './traslations/es/home-account.json'
import create_contacts_new_en from './traslations/en/create-contacts-new.json'
import create_contacts_new_es from './traslations/es/create-contacts-new.json'
import campaign_bulk_en from './traslations/en/campaign-bulk.json'
import campaign_bulk_es from './traslations/es/campaign-bulk.json'
import campaign_voz_en from './traslations/en/campaign-voz.json'
import campaign_voz_es from './traslations/es/campaign-voz.json'
import campaign_reports_en from './traslations/en/campaign-reports.json'
import campaign_reports_es from './traslations/es/campaign-reports.json'
import users_en from './traslations/en/users.json'
import users_es from './traslations/es/users.json'
import balance_en from './traslations/en/balance.json'
import balance_es from './traslations/es/balance.json'
import account_en from './traslations/en/account.json'
import account_es from './traslations/es/account.json'
import assing_balance_en from './traslations/en/assing-balance.json'
import assing_balance_es from './traslations/es/assing-balance.json'
import history_balance_en from './traslations/en/history-balance.json'
import history_balance_es from './traslations/es/history-balance.json'
import modal_en from './traslations/en/modal.json'
import modal_es from './traslations/es/modal.json'
import SpinnerProvider from './contexts/spinnerContext';
import feedback_en from './traslations/en/feedback.json'
import feedback_es from './traslations/es/feedback.json'
import menu_en from './traslations/en/menu.json'
import menu_es from './traslations/es/menu.json'
import shortlink_campaign_en from './traslations/en/shortlink-campaign.json'
import shortlink_campaign_es from './traslations/es/shortlink-campaign.json'
import rcs_campaign_en from './traslations/en/rcs-campaign.json'
import rcs_campaign_es from './traslations/es/rcs-campaign.json'
import whatsapp_campaign_en from './traslations/en/whatsapp-campaign.json'
import whatsapp_campaign_es from './traslations/es/whatsapp-campaign.json'
import contacts_create_en from './traslations/en/contacts-create.json'
import contacts_create_es from './traslations/es/contacts-create.json'
import contacts_history_en from './traslations/en/contacts-history.json'
import contacts_history_es from './traslations/es/contacts-history.json'
import shortlink_report_en from './traslations/en/shortlink-report.json'
import shortlink_report_es from './traslations/es/shortlink-report.json'
import history_templates_en from './traslations/en/templates-history.json'
import history_templates_es from './traslations/es/templates-history.json'
import sms_api_key_en from './traslations/en/sms-api-key.json'
import sms_api_key_es from './traslations/es/sms-api-key.json'
import campaign_history_en from './traslations/en/campaign-history.json'
import campaign_history_es from './traslations/es/campaign-history.json'
import best_practice_en from './traslations/en/best-practice.json'
import best_practice_es from './traslations/es/best-practice.json'
import company_en from './traslations/en/company.json'
import company_es from './traslations/es/company.json'
import dashboard_channel_select_en from './traslations/en/dashboard-channel-select.json'
import dashboard_channel_select_es from './traslations/es/dashboard-channel-select.json'
import webhook_url_mo_en from './traslations/en/webhook-url-mo.json'
import webhook_url_mo_es from './traslations/es/webhook-url-mo.json'

i18next.init({
  interpolation: { escapeValue: false },
  lng: "en",
  resources: {
    en: {
      "create-contacts-new": create_contacts_new_en,
      "home-company": home_company_en,
      "home-carrier": home_carrier_en,
      "home-account": home_account_en,
      "campaign-bulk": campaign_bulk_en,
      "campaign-voz": campaign_voz_en,
      "campaign-reports": campaign_reports_en,
      "users": users_en,
      "balance": balance_en,
      "account": account_en,
      "assing-balance": assing_balance_en,
      "history-balance": history_balance_en,
      "modal": modal_en,
      "feedback": feedback_en,
      "menu": menu_en,
      "shortlink-campaign": shortlink_campaign_en,
      "rcs-campaign": rcs_campaign_en,
      "whatsapp-campaign": whatsapp_campaign_en,
      "create-contacts": contacts_create_en,
      "contacts-history": contacts_history_en,
      "shortlink-report": shortlink_report_en,
      "templates-history": history_templates_en,
      "sms-api-key": sms_api_key_en,
      "campaign-history": campaign_history_en,
      "best-practice": best_practice_en,
      "company": company_en,
      "dashboard-channel-select": dashboard_channel_select_en,
      "webhook-url-mo": webhook_url_mo_en
    },
    es: {
      "create-contacts-new": create_contacts_new_es,
      "home-company": home_company_es,
      "home-carrier": home_carrier_es,
      "home-account": home_account_es,
      "campaign-bulk": campaign_bulk_es,
      "campaign-voz": campaign_voz_es,
      "campaign-reports": campaign_reports_es,
      "users": users_es,
      "balance": balance_es,
      "account": account_es,
      "assing-balance": assing_balance_es,
      "history-balance": history_balance_es,
      "modal": modal_es,
      "feedback": feedback_es,
      "menu": menu_es,
      "shortlink-campaign": shortlink_campaign_es,
      "rcs-campaign": rcs_campaign_es,
      "whatsapp-campaign": whatsapp_campaign_es,
      "create-contacts": contacts_create_es,
      "contacts-history": contacts_history_es,
      "shortlink-report": shortlink_report_es,
      "templates-history": history_templates_es,
      "sms-api-key": sms_api_key_es,
      "campaign-history": campaign_history_es,
      "best-practice": best_practice_es,
      "company": company_es,
      "dashboard-channel-select": dashboard_channel_select_es,
      "webhook-url-mo": webhook_url_mo_es
    }
  }
});

ReactDOM.render(
  <Router>
    <AuthProvider>
      <I18nextProvider i18n={i18next}>
        <SpinnerProvider>
          <CurrencyProvider>
            <TimezoneProvider>
              <BreadcrumbProvider>
                <HeaderOptionProvider>
                  <RatesAccountProvider>
                    <App></App>
                  </RatesAccountProvider>
                </HeaderOptionProvider>
              </BreadcrumbProvider>
            </TimezoneProvider>
          </CurrencyProvider>
        </SpinnerProvider>
      </I18nextProvider>
    </AuthProvider>
  </Router>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();