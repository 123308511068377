import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getGradientColor, purpleColor } from "../../../../utils/colorsCss";
import { currencyFormat, getMonthByDate } from "../../../../utils/helpers";
import HelperButton from "../../../HelperButton/HelperButton";
import styles from "./BillingSms.module.css";
import { faCaretDown, faCheckCircle, faExclamationTriangle, faEquals } from "@fortawesome/pro-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useEffect } from "react";
import { Spin } from "antd";
import DataApi from "../../../../Api/DataApi";
import { CHANELLIST } from "../../../../utils/const";

const BillingSms = () => {

  const [t] = useTranslation("home-carrier");
  const {getBilling} = DataApi()

  const [value, setValue] = useState(0);
  const [variation, setVariation] = useState(0);
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    getInitialData()
    .then(response => {
      const {billingSmsValue, creditVariation} = response;
      setValue(billingSmsValue);
      setVariation(creditVariation);
      setLoading(false);
    })
    .catch(error => {
      console.error(error);
      setLoading(false);
    })
  }, [])

  const getInitialData = async () => {
    let response = await getBilling(CHANELLIST.SMS.key);
    return { billingSmsValue: response.expenses, creditVariation: response.percentageVariation }
  }

  const getVariationColor = () => {
    if(variation < 0) {
      return styles['variation-red'];
    }

    if(variation >= 0) {
      return styles['variation-orange'];
    }

    if(variation > 80) {
      return styles['variation-green'];
    }
  }

  const getVariationIcon = () => {
    if(variation < 0) {
      return <FontAwesomeIcon style={{ marginRight: "15px", fontSize: "1.2em" }} icon={faCaretDown} />;
    }

    if(variation === 0) {
      return <FontAwesomeIcon style={{ marginRight: "15px", fontSize: "1.0em" }} icon={faEquals} />;
    }

    if(variation <= 80) {
      return <FontAwesomeIcon style={{ marginRight: "15px", fontSize: "0.8em" }} icon={faExclamationTriangle} />;
    }

    if(variation > 80) {
      return <FontAwesomeIcon style={{ marginRight: "15px", fontSize: "0.9em" }} icon={faCheckCircle} />;
    }
  }

  return (
    <div className={styles['dashboard-header-spinner']}>
      <Spin spinning={loading}>
        <div className={styles['dashboard-header-card']}>
          <div className={styles['dashboard-header-card-title']}>
            <p>{t("billing-sms.title")}</p>
            <HelperButton
              message={t("billing-sms.tootltip-title")}
              color={getGradientColor(purpleColor)}
            />
          </div>
          <div className={styles['dashboard-header-card-value']}>
            <p>{currencyFormat(value)} USD</p>
          </div>
          <div className={styles['dashboard-header-card-footer']}>
            <p>{getMonthByDate(new Date())}</p>
            <div className={`${styles['dashboard-header-card-footer-variation']} ${getVariationColor()}`}>
              <p>{getVariationIcon()} {Math.abs(variation)} %</p>   
            </div>
          </div>      
        </div>
      </Spin>
    </div>
  );
}
 
export default BillingSms;