import { useState, useRef, Fragment, useEffect } from "react";
import styles from "./MessageWildcard.module.css";
import ContentEditable from 'react-contenteditable'
import CONSTANTS from "../../utils/const";

const MessageWildcard = ({wildcardsFields, onChangeMessage, onChangeWildCards, defaultMessage, defaultWildCards, disabled}) => {

  const contentEditableRef = useRef();
  const [message, setMessage] = useState(defaultMessage ? defaultMessage : {
    message: "",
    html: ""
  });
  const [wildcards, setWildcards] = useState(defaultWildCards ? defaultWildCards : []);

  useEffect(() => {
    if(defaultMessage.message.length > 0 && defaultMessage.html.length === 0) {
      rebuildHtmlMessage();
    } 
  }, [defaultMessage]);

  const rebuildHtmlMessage = () => {
    var messageCopy = defaultMessage.message;
    var html = defaultMessage.message;
    var regex = /\@#(.*?)\@#/;
    var matched = regex.exec(messageCopy);
    var hasWildcard = matched?.length > 0;
    var messageWildcards = [];
    var id = 0;

    while (hasWildcard) {
      id += 1;
      messageWildcards.push({
        id: id,
        content: matched[0],
        value:  matched[1]
      });
      messageCopy = messageCopy.replace(matched[0], '');
      matched = regex.exec(messageCopy);
      hasWildcard = matched?.length > 0;
    }

    setWildcards(messageWildcards);

    for (let i = 0; i < messageWildcards.length; i++) {
      let newValue = getHtmlWildCard(messageWildcards[i].id, messageWildcards[i].value);
      html = html.replace(messageWildcards[i].content, newValue)
    }

    setMessage({
      message: defaultMessage.message,
      html: html
    });
  }

  const handleChange = (e) => {

    let msHtmlByWildcardDiv = e.target.value
      .replaceAll( /(<([^>]+)>)/ig, CONSTANTS.DIV)
      .replaceAll(/&amp;/g, '&')
      .replaceAll(/&lt;/g, '<')
      .replaceAll(/&gt;/g, '>');

    let msWildCards = changeDivsInWildcards(msHtmlByWildcardDiv);
    let ms = msWildCards.replaceAll(`${CONSTANTS.DIV}`, '').replaceAll(/&nbsp;/g, ' ');

    for (let i = 0; i < wildcards.length; i++) {
      const id = wildcards[i].id;
      if(!e.target.value.includes(`wildcard_${id}`)) {
        let newWildCards = wildcards.filter(x => x.id !== id);
        if(onChangeWildCards) {
          onChangeWildCards(newWildCards);
        }
        setWildcards(prevState => {
          return newWildCards;
        });
      }
    }

    setMessage( prevState => {
      return {
        ...prevState,
        message: ms,
        html: e.target.value
      };
    });

    if(onChangeMessage) {
      onChangeMessage({
        message: ms,
        html: e.target.value
      });
    }
  }

  const changeDivsInWildcards = (text) => {
    let newText = text;
    wildcardsFields.forEach(element => {
      newText = newText.replaceAll(`${CONSTANTS.DIV}${element}${CONSTANTS.DIV}×${CONSTANTS.DIV}${CONSTANTS.DIV}`, `${CONSTANTS.WILDCARD}${element}${CONSTANTS.WILDCARD}`)
    });
    return newText;
  }

  const addWildcard = (value) => {
    if(disabled === false) {
      var myElement = document.getElementById('message-area');
      var startPosition = getCaretCharacterOffsetWithin(myElement); // myElement.selectionStart;

      let id = "";
      let idButton = "";
      let html = message.html ? message.html.replaceAll(/&nbsp;/g, ' ') : "";
      let messageMirror = message.message;

      for (let i = 0; i < wildcards.length; i++) {
        messageMirror = messageMirror.replaceAll(`${CONSTANTS.WILDCARD}${wildcards[i].value}${CONSTANTS.WILDCARD}`, `${wildcards[i].value}x`);
      }

      let firstPart =  messageMirror.substr(0, startPosition);
      let secondPart = messageMirror.substr(startPosition, messageMirror.length);

      if(wildcards.length === 0) {
        id = 1;
        let wild = [...wildcards, {
          id: 1,
          content: `${CONSTANTS.WILDCARD}${value}${CONSTANTS.WILDCARD}`,
          value:  `${value}`
        }];
        if(onChangeWildCards) {
          onChangeWildCards(wild);
        }
        setWildcards(wild);
      } else {
        let lastId = Math.max.apply(Math, wildcards.map(function(x) { return x.id; }));
        id = lastId + 1;
        let wild = [...wildcards, {
          id: lastId + 1,
          content: `${CONSTANTS.WILDCARD}${value}${CONSTANTS.WILDCARD}`,
          value:  `${value}`
        }];
        if(onChangeWildCards) {
          onChangeWildCards(wild);
        }
        setWildcards(wild);
      }

      html = message.message ?
      `${firstPart}${getHtmlWildCard(id, value)}${secondPart}` :
      `${getHtmlWildCard(id, value)}`;

      //Volver a generar el html
      for (let i = 0; i < wildcards.length; i++) {
        let newValue = getHtmlWildCard(wildcards[i].id, wildcards[i].value);
        html = html.replace(`${wildcards[i].value}x`, newValue);
      }

      var ms = html.replaceAll( /(<([^>]+)>)/ig, CONSTANTS.WILDCARD);
      ms = ms.replaceAll(`${CONSTANTS.WILDCARD}×${CONSTANTS.WILDCARD}`, '');

      setMessage({
        message: ms,
        html: html
      });

      putEndFocus();

      if(onChangeMessage) {
        onChangeMessage({
          message: ms,
          html: html
        });
      }
    }
  }

  const getCaretCharacterOffsetWithin = (element) => {
    var caretOffset = 0;
    var doc = element.ownerDocument || element.document;
    var win = doc.defaultView || doc.parentWindow;
    var sel;
    if (typeof win.getSelection != "undefined") {
        sel = win.getSelection();
        if (sel.rangeCount > 0) {
            var range = win.getSelection().getRangeAt(0);
            var preCaretRange = range.cloneRange();
            preCaretRange.selectNodeContents(element);
            preCaretRange.setEnd(range.endContainer, range.endOffset);
            caretOffset = preCaretRange.toString().length;
        }
    } else if ( (sel = doc.selection) && sel.type != "Control") {
        var textRange = sel.createRange();
        var preCaretTextRange = doc.body.createTextRange();
        preCaretTextRange.moveToElementText(element);
        preCaretTextRange.setEndPoint("EndToEnd", textRange);
        caretOffset = preCaretTextRange.text.length;
    }
    return caretOffset;
  }

  const onDragOver = (ev) =>{
    ev.preventDefault();
  }

  const onDrop = (e)=>{
    let item = e.dataTransfer.getData("item");
    addWildcard(item);
  }

  const onDragStart = (ev,item) =>{
    ev.dataTransfer.setData("item",item);
  }

  const onDelete = (e) => {
    let el = e.target;
    const TAGNAME = 'BUTTON';

    while (el && el !== e.currentTarget && el.tagName !== TAGNAME) {
      el = el.parentNode;
    }

    if (el && el.tagName === TAGNAME) {

      let id = el.id.replaceAll('button_', '');
      let result = wildcards.find(w => w.id === parseInt(id));

      if(result) {
        let htmlToFind = getHtmlWildCard(id, result.value);
        let html = message.html.replaceAll(htmlToFind, '');

        putEndFocus();

        let e = {
          target: {
            value: html
          }
        }

        let newWildCards = wildcards.filter(x => x.id !== parseInt(id));
        setWildcards(newWildCards);

        if(onChangeWildCards) {
          onChangeWildCards(newWildCards);
        }

        handleChange(e)
      }
    }
  }

  const getHtmlWildCard = (id, value) => {
    let stl = value === CONSTANTS.SHORTLINK ? `${styles["message-area-wildcard"]} ${styles["message-area-wildcard-shortlink"]}` : styles["message-area-wildcard"];
    return `<div id="wildcard_${id}" class="${stl}" contenteditable="false">${value}<button id="button_${id}" class="${styles['message-area-wildcard-delete']}">×</button></div>`
  }

  const getWildCardStyle = (value) => {
    let stl = value === CONSTANTS.SHORTLINK ? `${styles["wildcards"]} ${styles["wildcard-shortlink"]}` : styles["wildcards"];
    return stl;
  }

  const getWildCardId = (value) => {
    let id = value === CONSTANTS.SHORTLINK ? `wildcard_shortlinks` : value;
    return id;
  }

  const putEndFocus = () => {
    const contentEditable = contentEditableRef.current;
    contentEditable.focus();

    const range = document.createRange();
    const selection = window.getSelection();
    range.selectNodeContents(contentEditable);
    range.collapse(false);
    selection.removeAllRanges();
    selection.addRange(range);
  }

  return (
    <Fragment>
      <ContentEditable
        id="message-area"
        className={styles['message-area']}
        innerRef={contentEditableRef}
        onDragOver={e=>onDragOver(e)}
        onDrop={e=>onDrop(e)}
        html={message.html} // innerHTML of the editable div
        disabled={disabled}       // use true to disable editing
        onChange={handleChange} // handle innerHTML change
        tagName='div' // Use a custom HTML tag (uses a div by default)
        onClick={onDelete}
      />
      <div className={styles["wildcards-list"]}>
        {
          wildcardsFields.map((item,index)=>(
            <span
              id={getWildCardId(item)}
              key={index}
              onClick={() => addWildcard(item)}
              className={getWildCardStyle(item)}
              onDragStart={e=>onDragStart(e,item)}
              draggable
            >
              {item}
            </span>
          ))
        }
      </div>
    </Fragment>
  );
}

export default MessageWildcard;